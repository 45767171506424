import React from "react";
import { ThemeContext } from "../layouts";
import Article from "../components/Article";
import Headline from "../components/Article/Headline";
import Author from "../components/Post/Author";
import Seo from "../components/Seo";

import core from "../images/jpg/core.jpg";
import jackie from "../images/jpg/jackie.jpg";

const Agenda = () => (
  <React.Fragment>
    <ThemeContext.Consumer>
      {theme => (
        <Article theme={theme}>
          <header>
            <Headline title="Contact Us" theme={theme} />
          </header>
          <Author theme={theme} img={core} note={<div style={{ marginTop: '5px' }}>
            <b>BLD SF Bay Area Singles Core</b>
            <p>Roneil | Rachel | Mimi | Artchie</p>
            <p style={{ marginTop: '15px' }}>They are here to lead and unite our singles ministries around the continent and make sure that we
            all have a fun time encountering God together, so please feel free to reach out to them if you have any questions.</p>
            <p style={{ marginTop: '10px' }}><a href="mailto:bldbaysingles@gmail.com">bldbaysingles@gmail.com</a></p>
          </div>} />
          <Author theme={theme} img={jackie} note={<div style={{ marginTop: '5px' }}>
            <b>Marriott Courtyard Event Specialist</b>
            <p>Jackie</p>
            <p style={{ marginTop: '15px' }}>If you have any specific questions about Marriott Courtyard (our recommended hotel), feel free to contact Jackie.  She can help answer any questions regarding their double beds, plush pillows and the like.</p>
            <p style={{ marginTop: '10px' }}><a href="mailto:jackie.villacorta@marriott.com">jackie.villacorta@marriott.com</a></p>
          </div>} />

          {/* --- STYLES --- */}
          <style jsx>{`
            h2 {
              margin: 0 0 0.5em;
            }
            h2 :global(svg) {
              height: 0.8em;
              fill: ${theme.color.brand.primary};
            }
            .images {
              width: 100%;
              display: flex;
              flex-wrap: wrap;
              justify-content: center;
              align-items: center;
            }
            .images img {
              width: 100%;
              height: auto;
              display: block;
              margin: 5px;
              max-width: 400px;
            }
            a {
              font-weight: 600;
              color: #6b191f;
              -webkit-text-decoration: underline;
              text-decoration: underline;
            }
          `}</style>
        </Article>
      )}
    </ThemeContext.Consumer>
    <Seo />
  </React.Fragment>
);

export default Agenda;
