import React from "react";
import PropTypes from "prop-types";

import config from "../../../content/meta/config";
import avatar from "../../images/png/avatar.png";

const Author = props => {
  const { note, theme, img } = props;

  return (
    <React.Fragment>
      <div className="author">
        <div className="avatar">
          <img
            src={img || avatar}
            alt={config.siteTitle}
          />
        </div>
        <div className="note">{note}</div>
      </div>

      {/* --- STYLES --- */}
      <style jsx>{`
        .author {
          padding: ${theme.space.m} 0;
          border-top: 1px solid ${theme.line.color};
          display: flex;
          flex-wrap: wrap;
        }
        .avatar {
          margin: 5px 20px 0 0;
          overflow: hidden;
        }
        .avatar img {
          height: auto;
          width: 300px;
        }
        .note {
          font-size: 1.2em;
          line-height: 1.6;
          max-width: 380px;
        }
        @media only screen and (max-width: 400px) {
          .avatar {
            margin: 0;
          }
          .avatar img {
            height: auto;
            width: 100%;
          }
        }
        @media only screen and (max-width: 1000px) {
          .note {
            max-width: 1000px;
          }
        }
      `}</style>
    </React.Fragment>
  );
};

Author.propTypes = {
  note: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired
};

export default Author;
